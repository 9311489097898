<template>
    <portal to="mct-infos" class="d-flex flex-column justify-content-end align-items-start">
        <!--<i class="fa fa-info" aria-hidden="true" @click="show = !show" title="Mostrar ou Ocultar Informações"></i>-->
        <div v-if="data.audit_data_cadastro && data.audit_data_cadastro != null && typeof(data.audit_data_cadastro) != undefined" :class="showInfos === true ? 'visible' : ''" class="mct-infos-card mct-infos-cadastro flex-column justify-content-end align-items-star mb-3">
            <h1>Inclusão</h1>
            <div class="d-flex flex-row justify-content-start align-items-center">
                <div>
                    <img src="@/assets/images/sem_foto_branco.png" class="rounded-circle bg-light" alt="Usuário">    
                </div>            
                <div class="d-flex flex-column justify-content-start align-items-start">
                    <span class="mct-infos-data"><i class="fa fa-calendar-o" aria-hidden="true"></i> {{data.audit_data_cadastro | datahora}}</span>
                    <span class="mct-infos-usuario">@{{data.audit_usu_cadastro}}</span>
                    <span class="mct-infos-local"><i class="fa fa-laptop" aria-hidden="true"></i> {{data.audit_local_cadastro}}</span>
                </div>
            </div>
        </div>

        <div v-if="data.audit_data_atualiza && data.audit_data_atualiza != null && typeof(data.audit_data_atualiza) != undefined" :class="showInfos === true ? 'visible' : ''" class="mct-infos-card mct-infos-atualiza flex-column justify-content-end align-items-star mb-3">
            <h1>Atualização</h1>
            <div class="d-flex flex-row justify-content-start align-items-center">
                <div>
                    <img src="@/assets/images/sem_foto_branco.png" class="rounded-circle bg-light" alt="Usuário">    
                </div>            
                <div class="d-flex flex-column justify-content-start align-items-start">
                    <span class="mct-infos-data"><i class="fa fa-calendar-o" aria-hidden="true"></i> {{data.audit_data_atualiza | datahora}}</span>
                    <span class="mct-infos-usuario">@{{data.audit_usu_atualiza}}</span>
                    <span class="mct-infos-local"><i class="fa fa-laptop" aria-hidden="true"></i> {{data.audit_local_atualiza}}</span>
                </div>
            </div>
        </div>

        <div v-if="data.audit_data_situacao && data.audit_data_situacao != null && typeof(data.audit_data_situacao) != undefined" :class="showInfos === true ? 'visible' : ''" class="mct-infos-card mct-infos-cancela flex-column justify-content-end align-items-star">
            <h1>Cancelamento</h1>
            <div class="d-flex flex-row justify-content-start align-items-center">
                <div>
                    <img src="@/assets/images/sem_foto_branco.png" class="rounded-circle bg-light" alt="Usuário">    
                </div>            
                <div class="d-flex flex-column justify-content-start align-items-start">
                    <span class="mct-infos-data"><i class="fa fa-calendar-o" aria-hidden="true"></i> {{data.audit_data_situacao | datahora}}</span>
                    <span class="mct-infos-usuario">@{{data.audit_usu_situacao}}</span>
                    <span class="mct-infos-local"><i class="fa fa-laptop" aria-hidden="true"></i> {{data.audit_local_situacao}}</span>
                </div>
            </div>
        </div>
    </portal>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
    props: ['data'],
    data () {
        return {
            //show: true
        }
    },
    computed: {
        ...mapState('Configs',['showInfos']),
    },    

}
</script>

<style>

</style>